import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ReactComponent as SkillIcon } from "../../assets/img/skill1.svg";
// import skill1 from "../../assets/img/skill1.svg"
import colorSharp from "../../assets/img/color-sharp.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import './Skills.css';
import ReactModal from "react-modal";
import { useState } from "react";
import modalbg from '../../assets/img/banner-bg.png';
ReactModal.setAppElement('#root');

export const Skills = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const [isBackEndModalOpen, setBackendModalOpen] = useState(false);
    const [isFrontEndModalOpen, setFrontEndModalOpen] = useState(false);
    const [isFullStackModalOpen, setFullStackModalOpen] = useState(false);
    const [isCloudModalOpen, setCloudModalOpen] = useState(false);

    const customModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            width: '50%',
            border: '1px solid #ccc',
            backgroundImage: `url(${modalbg})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',  // Semi-transparent black background
        },
    };

    return (
        <section className="skill" id="skills">
            <Container>
                <Row>
                    <Col>
                        <div className="skill-bx">
                            <TrackVisibility>
                                {({ isVisible }) =>
                                    <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                                        <h2>
                                            <br></br>
                                        </h2>
                                        {/* <p style={{ marginBottom: '2px' }}>Java, JavaScript, TypeScript, Python, SQL, NoSQL &</p> */}
                                        <p className="detail-p">In the curernt digital age, the internet represents a tidal wave of progress, with cloud computing emerging as a pivotal trend in the technological landscape. I've always harbored a deep fascination for cutting-edge technology and relish the challenges it presents. To me, this sector brims with potential and challenges, making it a thrilling arena to engage with. Immersed in the robust computer science environment at <a href="https://www.gatech.edu/" target="_blank" rel="noopener noreferrer" className="custom-cursor-skill">Georgia Tech</a>, I find myself greatly inspired. This backdrop fuels my enthusiasm for exploring the myriad possibilities that the future of technology holds.</p>
                                        <p className="detail-p"><br></br></p>
                                        <p className="detail-p">Today, equipped with the rigors of academia and invaluable internship experiences at amazing firms like <a href="https://aws.amazon.com/" target="_blank" rel="noopener noreferrer" className="custom-cursor-skill">Amazon</a> and <a href="https://www.lorentz.de/" target="_blank" rel="noopener noreferrer" className="custom-cursor-skill">LORENTZ</a> and <a href="https://hirebeat.co/" target="_blank" rel="noopener noreferrer" className="custom-cursor-skill">HireBeat</a>, I'm more committed than ever to my journey of never-ending learning. The tech landscape is ever-evolving, and my thirst for knowledge keeps me on my toes, constantly adapting and improving. 
                                        As I eagerly look ahead, I am keen to collaborate with a dynamic team where I can channel my unceasing zeal for learning, expertise, and passion into impactful contributions.</p>
                                        <p className="detail-p"><br></br></p>
                                        <p className="detail-p"><br></br></p>
                                        {/* <p className="detail-p">Languages: Java, JavaScript, TypeScript, Python, SQL, C, HTML, CSS, Bash</p>
                                        <p className="detail-p">Frameworks/Libraries: Spring Framework, Spring Boot, Node.js, Express.js, Vue.js, React, Bootstrap, MyBatis</p>
                                        <p className="detail-p">Databases: MySQL, MongoDB, Timestream, DynamoDB, Redis</p>
                                        <p className="detail-p">Cloud/DevOps: AWS, Git, Docker, Nginx, Maven, Tomcat</p>
                                        <p className="detail-p">Testing: Jasmine, Sinon, JUnit, Mockito</p> */}
                                    </div>}
                            </TrackVisibility>
                            <Carousel responsive={responsive} infinite={true} className="skill-slider">
                                <div className="item">
                                    {/* <img src={skill1} alt="Image" /> */}
                                    <SkillIcon className="custom-skill-svg" onClick={() => setBackendModalOpen(true)} width="204" height="204" />
                                    <ReactModal isOpen={isBackEndModalOpen} onRequestClose={() => setBackendModalOpen(false)} style={customModalStyles}>
                                        <div className="modal-content">
                                            <h3>Languages:</h3>
                                            <ul>
                                                <li>Java, JavaScript, TypeScript, SQL</li>
                                            </ul>

                                            <h3>Frameworks/Libraries:</h3>
                                            <ul>
                                                <li>Spring Framework, Spring Boot, Node.js, Express.js, MyBatis</li>
                                            </ul>

                                            <h3>Databases:</h3>
                                            <ul>
                                                <li>MySQL, MongoDB, Timestream, DynamoDB, Redis</li>
                                            </ul>

                                            <h3>Cloud/DevOps:</h3>
                                            <ul>
                                                <li>AWS, Git, Docker, Nginx, Maven, Tomcat</li>
                                            </ul>

                                            <h3>Relevant Experience:</h3>
                                            <ul>
                                                <li>Lorentz: Implemented scalable AWS Lambda functions in TypeScript for real-time data handling of 10,000+ records daily.</li>
                                                <li>HireBeat: Implemented a secure user login API and data aggregation engine using Node.js, Express.js, and MongoDB.</li>
                                                <li>Buzz Takeout: Implemented Redis for in-memory data storage, ensuring consistent performance for real-time order processing speed.</li>
                                            </ul>
                                        </div>
                                    </ReactModal>
                                    <h5>Backend Develoment</h5>
                                </div>

                                <div className="item">
                                    {/* <img src={skill1} alt="Image" /> */}
                                    <SkillIcon className="custom-skill-svg" onClick={() => setFrontEndModalOpen(true)} width="204" height="204" />
                                    <ReactModal isOpen={isFrontEndModalOpen} onRequestClose={() => setFrontEndModalOpen(false)} style={customModalStyles}>
                                        <div className="modal-content">
                                            <h3>Languages:</h3>
                                            <ul>
                                                <li>JavaScript, TypeScript, HTML, CSS</li>
                                            </ul>

                                            <h3>Frameworks/Libraries:</h3>
                                            <ul>
                                                <li>Vue.js, React, Bootstrap</li>
                                            </ul>

                                            <h3>Relevant Experience:</h3>
                                            <ul>
                                                <li>HireBeat: Crafted an intuitive Vue.js web interface, achieving an 80% user approval rate.</li>
                                                <li>Buzz Takeout: Developed a user-friendly web application interface, emphasizing real-time order processing speed and clarity of interface interactions.</li>
                                            </ul>
                                        </div>
                                    </ReactModal>
                                    <h5>Frontend Develoment</h5>
                                </div>


                                <div className="item">
                                    {/* <img src={skill1} alt="Image" /> */}
                                    <SkillIcon className="custom-skill-svg" onClick={() => setFullStackModalOpen(true)} width="204" height="204" />
                                    <ReactModal isOpen={isFullStackModalOpen} onRequestClose={() => setFullStackModalOpen(false)} style={customModalStyles}>
                                        <div className="modal-content">
                                            <h3>Languages:</h3>
                                            <ul>
                                                <li>Java, JavaScript, TypeScript, Python, SQL, C, HTML, CSS</li>
                                            </ul>

                                            <h3>Frameworks/Libraries:</h3>
                                            <ul>
                                                <li>Spring Framework, Spring Boot, Node.js, Express.js, Vue.js, React, Bootstrap, MyBatis</li>
                                            </ul>

                                            <h3>Databases:</h3>
                                            <ul>
                                                <li>MySQL, MongoDB, Redis</li>
                                            </ul>

                                            <h3>Cloud/DevOps:</h3>
                                            <ul>
                                                <li>AWS, Git, Docker, Nginx, Maven, Tomcat</li>
                                            </ul>

                                            <h3>Relevant Experience:</h3>
                                            <ul>
                                                <li>HireBeat: Developed a full-stack web application for candidate screening. Handled frontend with Vue.js and backend with Node.js, Express.js, and MongoDB.</li>
                                                <li>Buzz Takeout: Led the development of a restaurant management system using Java, Spring Boot, Maven, and MySQL. Ensured real-time order processing and system enhancement.</li>
                                            </ul>
                                        </div>
                                    </ReactModal>
                                    <h5>Full-stack Develoment</h5>
                                </div>

                                
                                <div className="item">
                                    {/* <img src={skill1} alt="Image" /> */}
                                    <SkillIcon className="custom-skill-svg" onClick={() => setCloudModalOpen(true)} width="204" height="204" />
                                    <ReactModal isOpen={isCloudModalOpen} onRequestClose={() => setCloudModalOpen(false)} style={customModalStyles}>
                                        <div className="modal-content">
                                            <h3>Languages:</h3>
                                            <ul>
                                                <li>JavaScript, TypeScript</li>
                                            </ul>

                                            <h3>Frameworks/Libraries:</h3>
                                            <ul>
                                                <li>Serverless</li>
                                            </ul>

                                            <h3>Databases:</h3>
                                            <ul>
                                                <li>Timestream, DynamoDB</li>
                                            </ul>

                                            <h3>Cloud/DevOps:</h3>
                                            <ul>
                                                <li>AWS, Git, Docker, Nginx</li>
                                            </ul>

                                            <h3>Relevant Experience:</h3>
                                            <ul>
                                                <li>Lorentz (Germany): Integrated AWS Timestream Database for a 30% data response boost. Also worked on Data Processing API with AWS Lambda functions.</li>
                                            </ul>
                                        </div>
                                    </ReactModal>
                                    <h5>Cloud Service</h5>
                                </div>

                                <div className="item">
                                    {/* <img src={meter1} alt="Image" /> */}
                                    <SkillIcon className="custom-skill-svg" width="204" height="204" />
                                    <h5>Testing</h5>
                                </div>
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-left" src={colorSharp} />
        </section>
    )
}